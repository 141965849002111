<template>

  <main class="kb-main"  >
    <lxp-main-header :show-breadcrumb="true" :show-custom-title="true" :show-excel-download="false" :title="`직무수행교육 현황`"/>
    <div class="main-content main-component">

      <FcplList v-if="isSearch === false"  :items="fcplList"
                :isLoading="isLoading"/>

      <FcplSearchList v-else :items="fcplList"
                :isLoading="isLoading"/>

    </div>
    <template v-if="isLoading">
      <LoadingDiv v-model="isLoading" />
    </template>
  </main>

</template>


<script>

// import {useRoute} from "vue-router/dist/vue-router";
import {useStore} from "vuex";
import LxpMainHeader from '@/components/common/LxpMainHeader';
import {ref, computed, reactive} from 'vue';

import navigationUtils from "@/assets/js/navigationUtils";
import {getListFunc} from "@/assets/js/ui.init";
import {ACT_GET_FCPL_LIST} from "@/store/modules/fcpl/fcpl";

import FcplList from '@/components/fcpl/FcplList';
import FcplSearchList from '@/components/fcpl/FcplSearchList';
import LoadingDiv from "@/components/common/LoadingDiv";


export default {
  name: 'FcplMain',
  components: {
    LoadingDiv,
    FcplList,
    FcplSearchList,
    LxpMainHeader
  },
  setup() {
    const fcplAdminList = ref([]); //특정요청자
    const fcplAdminDept = ref(['190450',
      '125530',
      '190410',
      '881200',
      '126030',
      '185940',
      '186020',
      '184691',
      '195950',
      '106570',
      '124020',
      '106650']); //특정부서
    const isAuthId = computed(() => fcplAdminList.value.includes(session.value.lrnerId));
    const isAuthDept = computed(() => fcplAdminDept.value.includes(session.value.deptCd));
   
    const view = ref('');
    // const route = useRoute();
    const store = useStore();

    const session = computed(() => store.state.auth.session);
    const payload = reactive({});

    const fcplList = ref([]);
    const isLoading = ref(false);
    const isSearch =  ref(false);

    const getPayload = ()=>{
      if(isAuthId.value || isAuthDept.value){
        // 권한요청부서 & 특정사용자
        isSearch.value = true;
        payload.deptCd = '';
        payload.lrnerId = '';
      }else{
        // if(session.value.vice) {
        //   payload.deptCd =session.value.deptCd ;
        // }else {
        //   payload.lrnerId =session.value.lrnerId ;
        // }
        payload.deptCd =session.value.deptCd ;
      }
    }

    const getFcplList =  () => {
      isLoading.value = true;

      getListFunc(`fcpl/${ACT_GET_FCPL_LIST}`, payload, fcplList, null, () => {
        isLoading.value = false;
      }, () => {
        isLoading.value = false;
      });

    }
    getPayload();
    getFcplList();


    return {
      fcplList,
      isLoading,
      isSearch,
      // pagedItems,
      // pagingFunc,
      payload,
      session,
      view,
      isMobile: navigationUtils.any()
    }

  }
};
</script>
