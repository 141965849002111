<template>
  <article class="content-section">
    <header class="section-header" v-if="!isMobile">
      <h4 class="title">상품숙지의무 현황 조회</h4>
    </header>
    <!-- training-list-container -->
    <div class="training-list-container">
      <!-- list-top -->
      <div class="list-top">
        <div class="top-column" v-if="!isMobile">
          <div>
            <div class="kb-form-search">

              <div class="kb-form-search-status">
                <h1>직원번호/직원명/부점명</h1>
              </div>
              <div class="kb-form-search-field">
                <input v-model.trim="keyword" type="text" class="kb-form-search-input" placeholder="검색" @keyup.enter="searchKeyword">
                <button class="kb-form-search-btn" @click="searchKeyword"><i class="icon-search">검색</i></button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="kb-table kb-table-bordered learning-tablelist">
        <table>
          <colgroup>
            <col style="width:5%">
            <col style="width:7%">
            <col style="width:6%">
            <col style="width:5%">
            <col style="width:5%">
            <col style="width:6%">
            <col style="width:6%">
            <col style="width:4%">
            <col style="width:4%">
            <col style="width:6%">
            <col style="width:6%">
            <col style="width:6%">
            <col style="width:7%">
            <col style="width:6%">
          </colgroup>
          <thead>
          <tr>
            <th><span class="th-title">직원명</span></th>
            <th><span class="th-title">부점</span></th>
            <th><span class="th-title">직원번호</span></th>
            <th><span class="th-title">예금</span></th>
            <th><span class="th-title">카드</span></th>
            <th><span class="th-title">가계여신</span></th>
            <th><span class="th-title">기업여신</span></th>
            <th><span class="th-title">펀드</span></th>
            <th><span class="th-title">신탁</span></th>
            <th><span class="th-title">ISA 일임형</span></th>
            <th><span class="th-title">퇴직연금</span></th>
            <th><span class="th-title">방카슈랑스</span></th>
            <th><span class="th-title">장외파생상품</span></th>
            <th><span class="th-title">투자금융(IB)</span></th>
          </tr>
          </thead>
          <tbody>
          <tr v-if="filteredPagedItems.length === 0 && !isLoading" >
            <td colspan="14"><div class="text-truncate text-center"><strong class="td-text">검색 결과가 없습니다.</strong></div></td>
          </tr>
          <template v-else>
            <tr v-for="(item, index) in filteredPagedItems" :key="index">
              <td><span class="td-text">{{ item.lrnerNm }}</span></td>
              <td><span class="td-text">{{ item.deptNm }}</span></td>
              <td><span class="td-text">{{ item.lrnerId }}</span></td>
              <td><span class="td-text">{{ item.depositYn ? item.depositYn : 'N' }}</span></td>
              <td><span class="td-text">{{ item.cardYn ? item.cardYn : 'N' }}</span></td>
              <td><span class="td-text">{{ item.houseLoanYn ? item.houseLoanYn : 'N' }}</span></td>
              <td><span class="td-text">{{ item.coLoanYn ? item.coLoanYn : 'N' }}</span></td>
              <td><span class="td-text">{{ item.fundYn ? item.fundYn : 'N' }}</span></td>
              <td><span class="td-text">{{ item.trustYn ? item.trustYn : 'N' }}</span></td>
              <td><span class="td-text">{{ item.isaYn ? item.isaYn : 'N' }}</span></td>
              <td><span class="td-text">{{ item.retirePensionYn ? item.retirePensionYn : 'N' }}</span></td>
              <td><span class="td-text">{{ item.bancaYn ? item.bancaYn : 'N' }}</span></td>
              <td><span class="td-text">{{ item.otcYn ? item.otcYn : 'N' }}</span></td>
              <td><span class="td-text">{{ item.ibYn ? item.ibYn : 'N' }}</span></td>
            </tr>
          </template>

          </tbody>
        </table>
      </div>

      <CommonPaginationFront style="margin-top: 64px; margin-bottom:30px;" :paging="{
        pageNo: paging.pageNo,
        pageSize: paging.pageSize,
        totalCount: filteredItems.length
      }" :page-func="pagingFunc"/>

    </div>
    <!-- //training-list-container -->
  </article>
</template>

<script>

import {computed, reactive, ref} from 'vue';
import {closeToggles} from '@/assets/js/ui.init';
import CommonPaginationFront from '@/components/CommonPaginationFront';
import {paginate, timestampToDateFormat} from '@/assets/js/util';
import navigationUtils from '@/assets/js/navigationUtils';
import store from "@/store";


export default {
  name: "FcplSearchList",
  components: {CommonPaginationFront},
  props: {
    items: Array,
    isLoading: Boolean
  },
  setup(props)  {
    const currentDate = new Date();
    const isMobile = navigationUtils.any();

    const keyword = ref('');

    const filters = reactive({
      year: currentDate.getFullYear(),
      mediaCateCd: '',
      finish: 0,
      keyword: '',
    });


    const paging = reactive({
      pageNo: 1,
      pageSize: 10,
    });

    const yearOptions = (() => {
      const options = [];
      for(let i = currentDate.getFullYear(); i >= 1978 ; i--){
        options.push({value: i, display: `${i}년`});
      }
      return options;
    })();

    const cateOptions = computed(() => store.state.code.cates);


    const pagingFunc = (pageNo) => {
      paging.pageNo = pageNo;
    }

    const searchKeyword = () => {
      filters.keyword = keyword.value;
      pagingFunc(1);
    }



    const filteredItems = computed(() => props.items

        .filter(x => {
          // 직원번호 또는 부점 필터
          if(filters.keyword) return !!(x.deptNm.includes(filters.keyword) || filters.keyword === x.lrnerId|| filters.keyword === x.lrnerNm);
          return false;
        })


    );
    const filteredPagedItems = computed(() => paginate(filteredItems.value, paging.pageNo, paging.pageSize));



    return {

      keyword,
      filters,
      // toggles,
      paging,
      isMobile,

      yearOptions,
      cateOptions,
      // finishOptions,

      filteredItems,
      filteredPagedItems,

      closeToggles,
      pagingFunc,
      searchKeyword,

      // getLearnStatusText,
      // getLearningScore,
      //
      // goKgEduone,
      // goFastCampus,

      timestampToDateFormat,

    }
  }
}
</script>

<style scoped>

.lxp-layout .text-hazard {
  --bs-text-opacity: 1;
  color: var(--kb-red) !important;
}

</style>