<template>
  <article class="content-section section-spacing">

    <div class="certificate-list-container">
      <div class="kb-table kb-table-bordered-v2">
        <table>
          <colgroup>
            <col style="width:5%">
            <col style="width:7%">
            <col style="width:6%">
            <col style="width:5%">
            <col style="width:5%">
            <col style="width:6%">
            <col style="width:6%">
            <col style="width:4%">
            <col style="width:4%">
            <col style="width:6%">
            <col style="width:6%">
            <col style="width:6%">
            <col style="width:7%">
            <col style="width:6%">
          </colgroup>
          <thead>
          <tr>
            <th><span class="th-title">직원명</span></th>
            <th><span class="th-title">부점</span></th>
            <th><span class="th-title">직원번호</span></th>
            <th><span class="th-title">예금</span></th>
            <th><span class="th-title">카드</span></th>
            <th><span class="th-title">가계여신</span></th>
            <th><span class="th-title">기업여신</span></th>
            <th><span class="th-title">펀드</span></th>
            <th><span class="th-title">신탁</span></th>
            <th><span class="th-title">ISA 일임형</span></th>
            <th><span class="th-title">퇴직연금</span></th>
            <th><span class="th-title">방카슈랑스</span></th>
            <th><span class="th-title">장외파생상품</span></th>
            <th><span class="th-title">투자금융(IB)</span></th>
          </tr>
          </thead>
          <tbody>
          <tr v-if="items.length === 0 &&!isLoading">
            <td colspan="14"><div class="text-truncate text-center"><strong class="td-text">조회 이력이 없습니다.</strong></div></td>
          </tr>
          <template v-else>
            <tr v-for="(item, index) in pagedItems" :key="index">
              <td><span class="td-text">{{ item.lrnerNm }}</span></td>
              <td><span class="td-text">{{ item.deptNm }}</span></td>
              <td><span class="td-text">{{ item.lrnerId }}</span></td>
              <td><span class="td-text">{{ item.depositYn ? item.depositYn : 'N' }}</span></td>
              <td><span class="td-text">{{ item.cardYn ? item.cardYn : 'N' }}</span></td>
              <td><span class="td-text">{{ item.houseLoanYn ? item.houseLoanYn : 'N' }}</span></td>
              <td><span class="td-text">{{ item.coLoanYn ? item.coLoanYn : 'N' }}</span></td>
              <td><span class="td-text">{{ item.fundYn ? item.fundYn : 'N' }}</span></td>
              <td><span class="td-text">{{ item.trustYn ? item.trustYn : 'N' }}</span></td>
              <td><span class="td-text">{{ item.isaYn ? item.isaYn : 'N' }}</span></td>
              <td><span class="td-text">{{ item.retirePensionYn ? item.retirePensionYn : 'N' }}</span></td>
              <td><span class="td-text">{{ item.bancaYn ? item.bancaYn : 'N' }}</span></td>
              <td><span class="td-text">{{ item.otcYn ? item.otcYn : 'N' }}</span></td>
              <td><span class="td-text">{{ item.ibYn ? item.ibYn : 'N' }}</span></td>

            </tr>
          </template>
          </tbody>
        </table>
      </div>

      <div style="margin-top: 64px; margin-bottom:30px;">
        <CommonPaginationFront :paging="{
          pageNo: paging.pageNo,
          pageSize: paging.pageSize,
          totalCount: items.length
        }" :page-func="pagingFunc"/>
      </div>
    </div>
  </article>
</template>

<script>

import {numberComma, paginate, timestampToDateFormat} from '@/assets/js/util';
import {computed, reactive} from 'vue';
import CommonPaginationFront from '@/components/CommonPaginationFront';
import navigationUtils from '@/assets/js/navigationUtils';

export default {
  name: "FcplList",
  components: {CommonPaginationFront},
  props: {
    items: Array,
    isLoading: Boolean
  },
  setup(props) {
    const isMobile = navigationUtils.any();

    const paging = reactive({
      pageNo: 1,
      pageSize: 10
    });

    const pagedItems = computed(() => paginate(props.items
        , paging.pageNo, paging.pageSize));

    const pagingFunc = (pageNo) => {
      paging.pageNo = pageNo;
    }


    return {
      paging,
      pagedItems,
      pagingFunc,
      timestampToDateFormat,
      numberComma,
      isMobile,
    }
  }
}
</script>
